<template>
    <PageLoader v-if="is_loading || !application"/>
    <div v-else class="application-page">
        <AdminCard class="mb-32" icon="user" theme="client" title="open-apps-form-dib.clientInfo">
            <!-- div не трогать -->
            <div>
                <ClientForm v-model="application.customer" :key="application.customer.id" type="empty" incoming-phone=""
                            client-bank-editable disabled
                            ref="client-form" :selected-phone="selectedPhone"
                            @select-phone="selectedPhone = $event"/>
            </div>
        </AdminCard>
        <HistoryApplication :application="application" disabled :call-data="emulatedCallData" :messages="applicationMessages" @back-list="cancel"/>
    </div>
</template>
<script>
import {get, post, put} from "../../helpers/api";
import SelectedProblem from "../../components/selectedProblem";
import AppIcon from "../../components/partials/AppIcon";
import ChatWindowBody from "../../components/partials/chat/ChatWindowBody";
import PageLoader from "../../components/partials/PageLoader";
import ClientForm from "../../components/ClientForm";
import AdminCard from "../../components/partials/AdminCard";
import AppButton from "../../components/partials/AppButton";
import {parseMessageFromHistory} from "../../helpers/chats";
import HistoryApplication from "../../components/partials/HistoryApplication";
import AppTable from "../../components/partials/AppTable";
import StarsRating from "../../components/partials/StarsRating";
import FilterDate from "../../mixins/FilterDate";
import {getModifiedUserData} from "../../helpers/other";
import LangController from "../../services/LangController";
import {CallRoom} from "../../services/chat/ChatModel";

export default {
    name: 'ApplicationFormDIB',
    components: {
        StarsRating,
        AppTable,
        HistoryApplication,
        AppButton, AdminCard, ClientForm, PageLoader, ChatWindowBody, AppIcon, SelectedProblem},
    mixins: [FilterDate],
    data() {
        return {
            application: null,
            applicationMessages: [],
            create_client_loading: false,
            selectedPhone: '',
            is_loading: false,
        }
    },
    created() {
        this.loadApplication()
    },
    computed: {
        emulatedCallData() {
            if(!this.application) return null;
            return {
                ...new CallRoom({}),
                customers: [this.application.customer],
                selectedCustomer: this.application.customer.id,
                customersProblems: {
                    [this.application.customer.id]: [this.application]
                },
                isNewProblem: false
            }
        }
    },
    methods: {
        loadApplication() {
            this.is_loading = true
            let app_id = this.$route.params.id;
            if (!app_id) this.$router.push('/pages/404')

            get('/applications/' + app_id)
                .then(r => {
                    if (r.data.success) {
                        this.application = {
                            ...r.data.data,
                            customer: getModifiedUserData(r.data.data.customer, '')
                        }
                        this.selectedPhone = r.data.data.phone?.phone || '';
                        this.loadApplicationMessages();
                    }
                }).catch(e => {
                this.is_loading = false
            })
        },
        loadApplicationMessages() {
            get(`/chat-history/application?customerId=${this.application.customer.id}&applicationId=${this.$route.params.id}`)
                .then(response => {
                    this.applicationMessages = response.data.data.message.map(message => parseMessageFromHistory(message, this.application.customer.username));
                })
                .finally(() => {
                    this.is_loading = false
                })
        },
        cancel() {
            this.$router.push({
                path: '/open-apps-dib',
                query: this.$route.query
            });
        },
    },
}
</script>
